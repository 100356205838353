import styled from 'styled-components'

export const PlayButton = styled.button({
  zIndex: 10,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  width: 96,
  height: 96,
  background: 'rgba(0,0,0,0.3)',
  border: '3px solid #fff',
  borderRadius: '50%',
  transition: 'all 0.2s',

  '&:hover': {
    transform: 'translate(-50%, -50%) scale(1.1)',
  },

  svg: {
    position: 'absolute',
    top: 33,
    left: 36,
  },
})
