import { useState } from 'react'

import { PlayButton } from './style'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(import('react-player'), { ssr: false })

type Props = {
  url: string
}

const VideoPlayer = ({ url }: Props) => {
  const [showPlayer, setShowPlayer] = useState(false)

  return showPlayer ? (
    <ReactPlayer
      url={url}
      width="100%"
      height="100%"
      // playing
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}
      controls
    />
  ) : (
    <PlayButton type="button" onClick={() => setShowPlayer(true)}>
      <svg width={23} height={26} viewBox="0 0 23 26" fill="none">
        <path
          d="M22.9333 13.0667L0.533334 25.9993L0.533335 0.134048L22.9333 13.0667Z"
          fill="white"
        />
      </svg>
    </PlayButton>
  )
}

export default VideoPlayer
